import { getBrowserDeviceData } from './utils';
import axios, { AxiosError } from "axios";

const errorHandling = (error: any) => {
  if (error instanceof AxiosError) {
    if (error?.response?.data?.message === "ip-not-allowed") {
      throw new Error("ip-not-allowed")
    }
    if (error?.response?.data?.message === "employee-number-error") {
      throw new Error("employee-number-error")
    }
    if (error.status === 401 || error.status === 408) {
      throw error;
    } else if (error.status === 498) {
      const _window = window as any;
      if (_window.webkit?.messageHandlers?.bridge) {
        console.log("webkit found, posting message to ios webview...");
        _window.webkit.messageHandlers.bridge.postMessage({
          status: 498,
          message: "Invalid token",
        });
      } else {
        console.log("not webkit found, throwing error...");
        throw new axios.AxiosError(
          'Token expired',
          undefined,
          undefined,
          {
            status: 498,
            statusText: 'Token Expired',
            data: null,
          }
        );
      }
    }
  }
}

// api call might cause proxy error, so, set empty string for production,
// and for development "http://localhost:3000"
const baseUrl = "";

export function getQueryStringValue(key: string) {
  return decodeURIComponent(window.location.search.replace(new RegExp("^(?:.*[&\\?]" + encodeURIComponent(key).replace(/[.+*]/g, "\\$&") + "(?:\\=([^&]*))?)?.*$", "i"), "$1"));
}

export const getMagazineConfig = async () => {
  try {
    const token = getQueryStringValue('token');
    const magazine = getQueryStringValue('magazine');
    const url = `${baseUrl}/api/config/${magazine}`;
    const headers = {
      "Content-Type": "application/json",
      "X-Magazine-Id": magazine,
      Authorization: token,
    };
    const { data } = await axios.get(url, { headers });
    return data;
  } catch (error) {
    errorHandling(error)
  }
}

export const getLastClocking = async () => {
  try {
    const token = getQueryStringValue('token');
    const magazine = getQueryStringValue('magazine');
    const headers = {
      "Content-Type": "application/json",
      "X-Magazine-Id": magazine,
      Authorization: token,
    };

    const { data } = await axios.get(`${baseUrl}/api/clocking/last`, { headers })

    return data
  } catch (error) {
    console.log(error);
    errorHandling(error)
  }
}

export const postClocking = async (params: { inOut: string, employeeNumber: string }) => {
  try {
    const browserInfo = getBrowserDeviceData();
    const token = getQueryStringValue('token');
    const magazine = getQueryStringValue('magazine');
    const headers = {
      "Content-Type": "application/json",
      "X-Magazine-Id": magazine,
      Authorization: token,
    };
    const { data } = await axios.post(`${baseUrl}/api/clocking`, { ...params, ...browserInfo }, { headers });

    return data
  } catch (error) {
    errorHandling(error)
  }
}

export const checkIp = async (
  token: string
) => {
  const url = `${baseUrl}/api/clock/checkIp`;
  const headers = {
    Accept: "application/json",
    Authorization: token || "",
  };

  return await axios.get(url, { headers });
};